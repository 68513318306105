function getCurrentSize() {
	const points = {
		xs: 600,
		sm: 960,
		md: 1264,
		lg: 1904,
		xl: 99999,
	}

	const width = window.innerWidth
	for (let key in points) {
		if (width < points[key]) return key
	}
}

function makeBreakpointObj() {
	const size = getCurrentSize()

	return Object.freeze({
		name: size,
		xs: size === 'xs',
		sm: size === 'sm',
		smAndDown: ['xs', 'sm'].includes(size),
		smAndUp: ['sm', 'md', 'lg', 'xl'].includes(size),
		md: size === 'md',
		mdAndDown: ['xs', 'sm', 'md'].includes(size),
		mdAndUp: ['md', 'lg', 'xl'].includes(size),
		lg: size === 'lg',
		lgAndDown: ['xs', 'sm', 'md', 'lg'].includes(size),
		lgAndUp: ['lg', 'xl'].includes(size),
		xl: size === 'xl',
		mobile: size === 'xs',
		mobiletablet: ['xs', 'sm'].includes(size),
		tablet: size === 'sm',
		tabletdesktop: ['sm', 'md', 'lg', 'xl'].includes(size),
		desktop: ['md', 'lg', 'xl'].includes(size),
	})
}

let breakpoint = makeBreakpointObj()
window.addEventListener('resize', () => {
	breakpoint = makeBreakpointObj()
})

export function getBreakpoint() {
	return breakpoint
}

